import { css, keyframes } from "@emotion/react";

export const fadeInKeyframe = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const fadeIn = css`
    animation: ${fadeInKeyframe} 0.5s ease-in;
`;

export default fadeIn;
