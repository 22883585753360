import React from "react";

import ShopBaseTemplate from "../../../components/pages/Shop/ShopBaseTemplate";
import ShoppingCart from "../../../components/pages/Shop/ShoppingCart";

const ShopWarenkorbPage = () => {
    return (
        <ShopBaseTemplate>
            <ShoppingCart />
        </ShopBaseTemplate>
    );
};

export default ShopWarenkorbPage;
