import { sessionStorage } from "../../../../utils/StorageHandler";
import { getUrlParam } from "../../../../utils/urlParams";
import resolveWebView from "../../../../utils/webViewRedirect";

export const resolveShopWebviewRedirect = (
    fallbackUrl: string | null = null
) => {
    const sessionPath = sessionStorage.getItem("lastVisitedStoreFrontPath");
    if (sessionPath) {
        return resolveWebView(sessionPath);
    }
    const storefrontUrlPath = getUrlParam("storefrontPath");
    if (storefrontUrlPath) {
        return resolveWebView(storefrontUrlPath);
    }

    if (fallbackUrl) {
        return resolveWebView(fallbackUrl);
    }
    return resolveWebView("/");
};
