import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import resolveWebView from "../../../../utils/webViewRedirect";
import { PredefinedIconType } from "../../../shared/icons/Icon/Predefined";
import LinkComponent from "../../../shared/LinkComponent";
import Button, { ButtonColor } from "../../../shared/NewForms/Button";
import Headline from "../../../shared/NewHeadline";
import useSnackbar from "../../../shared/Snackbar/store";
import fadeIn from "../animations/fadeIn";
import BackLink from "../components/BackLink";
import { resolveShopWebviewRedirect } from "../helper/webview";
import CartPriceBreakdown from "./CartPriceBreakdown";
import CartProductItem from "./CartProductItem";
import useStore from "./store";

const Container = styled.div`
    margin-bottom: 5em;
`;

const CartContent = styled.div`
    display: flex;
    ${({ theme }) => theme.breakpoints.upTo.medium.css`
        flex-wrap: wrap;
    `}

    gap: 2.5em;
    margin-top: 2em;
`;

const HeadlineProductCount = styled.span`
    font-size: 1rem;
    font-weight: normal;
`;

const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 2.5em;
`;

const BackNavigation = styled.div`
    margin-top: 2em;
    margin-bottom: 2em;
    ${({ theme }) => theme.breakpoints.from.normal.css`
        margin-top: -60px;
        margin-bottom: 60px;
    `};
`;

const CheckoutButton = styled(Button)`
    margin-top: 1em;
`;

const storefrontPath = resolveShopWebviewRedirect("/adblue");

const ShoppingCart = () => {
    const [hasError, setHasError] = useState(false);
    const { confirm } = useSnackbar();
    const {
        loading,
        cart,
        setLoading,
        deleteProduct,
        changeQuantity,
        fetchCart,
    } = useStore(
        (state) => ({
            loading: state.loading,
            cart: state.cart,
            setLoading: state.setLoading,
            deleteProduct: state.deleteProduct,
            changeQuantity: state.changeQuantity,
            fetchCart: state.fetchCart,
        }),
        shallow
    );

    const fetchData = async () => {
        return fetchCart();
    };

    useEffect(() => {
        fetchData()
            .catch(() => setHasError(true))
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDeleteProduct = async (cartItemId: string) => {
        const confirmed = await confirm(
            "Artikel entfernen",
            "Möchtest Du den Artikel aus dem Warenkorb entfernen?"
        );

        if (confirmed) {
            setLoading(true);
            await deleteProduct(cartItemId);
            setLoading(false);
        }
    };

    const handleUpdateProduct = async (
        cartItemId: string,
        quantity: number
    ) => {
        setLoading(true);
        await changeQuantity(cartItemId, quantity);
        setLoading(false);
    };

    if (cart && cart.lineItems.length < 1) {
        return <EmptyCart backUrl={storefrontPath} />;
    }

    if (hasError) {
        return (
            <p>
                Es ist ein Fehler beim Abfragen des Warenkorbs aufgetreten.
                Bitte versuche es zu einem späteren Zeitpunkt erneut
            </p>
        );
    }

    return (
        <Container>
            <BackNavigation>
                <BackLink href={storefrontPath} />
            </BackNavigation>
            <Headline>
                Mein Warenkorb{" "}
                <HeadlineProductCount>
                    ({cart?.lineItems.length} Artikel)
                </HeadlineProductCount>
            </Headline>

            <CartContent>
                <ProductList>
                    {cart?.lineItems.map((product) => (
                        <CartProductItem
                            css={fadeIn}
                            key={product.id}
                            cartProduct={product}
                            onDelete={handleDeleteProduct}
                            onChangeQuantity={handleUpdateProduct}
                            loading={loading}
                        />
                    ))}
                </ProductList>

                {cart && cart?.price && cart?.deliveries && (
                    <CartPriceBreakdown
                        price={cart.price}
                        delivery={cart.deliveries[0]}
                        loading={loading}
                    >
                        <CheckoutButton
                            disabled={loading}
                            color={ButtonColor.red}
                            fullWidth
                            to={resolveWebView("/shop/bestellung")}
                        >
                            Zur Kasse
                        </CheckoutButton>
                    </CartPriceBreakdown>
                )}
            </CartContent>
        </Container>
    );
};

const EmptyCart = ({ backUrl = "/" }) => {
    return (
        <>
            <Headline>
                Mein Warenkorb{" "}
                <HeadlineProductCount>(0 Artikel)</HeadlineProductCount>
            </Headline>
            <CartContent>
                <div>
                    Es befinden sich aktuell keine Artikel im Warenkorb.
                    <LinkComponent
                        color="red"
                        link={{ text: "Weiter einkaufen", href: backUrl }}
                        icon={PredefinedIconType.arrowLeft}
                        leftIcon
                    />
                </div>
            </CartContent>
        </>
    );
};

export default ShoppingCart;
