import React from "react";

import styled from "@emotion/styled";

import { PredefinedIconType } from "../../../../shared/icons/Icon/Predefined";
import LinkComponent from "../../../../shared/LinkComponent";

const StyledLink = styled(LinkComponent)`
    cursor: pointer;
`;

const BackLink = ({ text = "Zurück", href = "" }) => {
    return (
        <StyledLink
            goBack
            link={{
                text: text,
                href: href,
            }}
            icon={PredefinedIconType.arrowLeft}
            leftIcon
            color="red"
        />
    );
};

export default BackLink;
